// 配置项
const type = 'villageLuo';

// 是不是本地发布 本地发布请求地址为 /
const isLocalTest = true;

const config = {
    dev: {
        baseURL: isLocalTest ? '/' : 'http://192.168.0.115:8886',
        tenantUrl: 'http://192.168.0.115:8887/#/redirect',
        residentUrl: 'http://192.168.0.115:8888/#/redirect'
    },
    prod: {
        baseURL: isLocalTest ? '/' : 'https://admin.dzjfz.com/interface',
        tenantUrl: 'https://yun.dzjfz.com/#/redirect',
        residentUrl: 'https://jm.dzjfz.com/#/redirect'
    },
    military: {
        baseURL: '/',
        tenantUrl: 'http://10.5.133.116:80/#/redirect',
        residentUrl: 'http://10.5.133.116:80/#/redirect'
    },
    villageLuo: {
        baseURL: '/',
        tenantUrl: 'https://yun.sslcsq.com/#/redirect',
        residentUrl: 'https://jm.sslcsq.com/#/redirect'
    },
}

export const baseURL = config[type].baseURL;
export const tenantUrl = config[type].tenantUrl;
export const residentUrl = config[type].residentUrl;

